import Header from '../components/Header'
import Meta from '../components/Meta'

const Service = () => {
  // page content


  return (
    <div>
        Service
    </div>
  )
}

export default Service